<template>
  <CModal
      :show.sync="modalAnular"
      :no-close-on-backdrop="true"
      :centered="true"
      :closeOnBackdrop="false"
      title="Anular Factura"
      size="lg"
      color="dark"
  >
    <template #header>
      Anular Factura
    </template>
    <div class="row pt-3">
      <div class="col">
        <div class="row">
          <template v-if="confirmarAnular">
            <div class="col-12">
              <label class="form-label" for="gestion">Motivo de anulación:</label>
              <select class="form-control pl-1 pr-1" id="motivo"
                      v-model="codigo_motivo" required="required">
                <option value="" selected>:: SELECCIONAR ::</option>
                <template v-for="(option,key) in motivos">
                  <option :value="option.codigoClasificador">
                    {{ option.descripcion }}
                  </option>
                </template>
              </select>
            </div>
            <div class="col-12 text-center pt-3">
              <button class="btn btn-success mx-2" @click="anularFactura">Anular Factura</button>
              <button class="btn btn-secondary mx-2" type="button" @click="close">Cerrar esta
                ventana
              </button>
            </div>
          </template>
          <template v-else>
          <div class="col text-center" v-if="esperandoRespuesta">
            <h4>Esperando a que el servicio de impuestos procese la factura</h4>
            <h5>Tiempo transcurrido
              <span
                  :class="'text-'+(tiempo<15?'success':(tiempo<30?'warning':'danger'))">
                                                        {{ tiempo }}</span> segundos.
              <button v-if="continuarEsperando" class="btn btn-sm btn-warning ms-2"
                      @click="tiempoAxios=0;continuarEsperando=false;temporizador();temporizadorAxios();">
                Seguir esperando la respuesta
              </button>
            </h5>
          </div>
          <div class="col text-center" v-if="!esperandoRespuesta">
            <h4>Factura Nro: <span>{{ factura.numeroFactura }}</span></h4>
            <h4>Estado: <span>{{ factura.estadoFactura }}</span></h4>
            <h4>Mensaje: <span>{{ factura.codigoError }} {{ factura.mensajeRespuesta }}</span></h4>
          </div>
          </template>
        </div>
      </div>
    </div>
    <template #footer-wrapper>
      <template v-if="!confirmarAnular">
      <h6 class="px-3"><em>En el listado de facturas tambien puede consultar el estado de la factura anulada</em>
        <button class="btn btn-secondary" type="button" @click="close">Cerrar esta
          ventana
        </button>
      </h6>
      </template>
      <template v-else>&nbsp;</template>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";

var modalAnular;
export default {
  name: "Modal",
  data() {
    return {
      confirmarAnular:true,
      tiempo: 0,
      tiempoAxios: 0,
      factura: null,
      intervalo: null,
      intervaloAxios: null,
      sede_id: 0,
      factura_id: 0,
      codigo_motivo: '',
      continuarEsperando: false,
      esperandoRespuesta: true,
      modalAnular: false,
      motivos:[]
    }
  },
  computed:{
    urlfactura() {
      let time = new Date().getTime()
      return this.$apiAdress + '/api/facturaenlinea/' + this.factura.id + '/facturapdf?token=' + localStorage.getItem('api_token')+'&timestamp='+time
    }
  },
  methods: {
    mostrar(sede_id, factura_id) {
      this.confirmarAnular=true
      this.sede_id = sede_id
      this.factura_id = factura_id
      modalAnular.modalAnular = true;
    },
    anularFactura() {
      this.confirmarAnular=false
      this.esperandoRespuesta = true
      this.tiempo = 0
      this.tiempoAxios = 0
      this.anular()
      this.loadItems(this.sede_id, this.factura_id)
      modalAnular.temporizador()
      modalAnular.temporizadorAxios()
    },
    close() {
      clearInterval(modalAnular.intervaloAxios)
      clearInterval(modalAnular.intervalo)
      modalAnular.modalAnular = false;
    },
    temporizador() {
      modalAnular.intervalo = setInterval(() => {
        modalAnular.tiempo++;
      }, 1000)
    },
    temporizadorAxios() {
      modalAnular.intervaloAxios = setInterval(() => {
        if (modalAnular.tiempoAxios > 21) {
          clearInterval(modalAnular.intervaloAxios)
          clearInterval(modalAnular.intervalo)
          modalAnular.continuarEsperando = true
          return;
        }
        modalAnular.tiempoAxios++;
        modalAnular.loadItems(this.sede_id, this.factura_id)
      }, 5000)
    },
    async loadItems(sede_id, factura_id) {
      modalAnular.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + sede_id + '/verify?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: factura_id}
      )
          .then(function (response) {
            console.log(response.data)
            modalAnular.factura = response.data.factura;
            console.log(response.data.factura.estadoFactura);
            if (response.data.factura.estadoFactura == 'ANULADA') {
              modalAnular.$emit('actualizaGrilla')
              clearInterval(modalAnular.intervaloAxios)
              clearInterval(modalAnular.intervalo)
              modalAnular.esperandoRespuesta = false
              axios.get(
                  modalAnular.urlfactura,
                  {responseType: 'blob'}
              )
                  .then((response) => {
                    modalAnular.modalAnular = false;
                    modalAnular.$emit('mostrarDocumento', response.data)
                  })
                  .catch(function (error) {
                    modalAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
                  });
            } else if (response.data.factura.estadoFactura === 'RECHAZADA') {
              clearInterval(modalAnular.intervaloAxios)
              clearInterval(modalAnular.intervalo)
              modalAnular.esperandoRespuesta = false
            } else if (response.data.factura.estadoFactura === 'VALIDADA') {
              if (modalAnular.tiempoAxios > 100) {
                clearInterval(modalAnular.intervaloAxios)
                clearInterval(modalAnular.intervalo)
                modalAnular.esperandoRespuesta = false
                modalAnular.$emit('showToast', 'Error', 'NO ES POSIBLE ANULAR LA FACTURA SONSULTE CON EL ADMINISTRADOR', 'danger')
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            modalAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
        modalAnular.processing = false
      })
    },
    async anular() {
      modalAnular.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + this.sede_id + '/anular?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: this.factura_id,codigo_motivo: this.codigo_motivo}
      )
          .then(function (response) {

          })
          .catch(function (error) {
            modalAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
        modalAnular.processing = false
      })
    },
  },
  mounted() {
    modalAnular = this

    axios.get(
        this.$apiAdress+'/api/facturaenlinea/0/opcionesanular?token=' + localStorage.getItem("api_token")
    )
        .then((response) => {
          modalAnular.motivos = response.data.motivos;
        })
        .catch(function (error) {
          modalAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
        });
  }
}
</script>
