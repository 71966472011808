<template>
  <CModal
      :show.sync="modalVerify"
      :no-close-on-backdrop="true"
      :centered="true"
      :closeOnBackdrop="false"
      title="Crear cliente"
      size="lg"
      color="dark"
  >
    <template #header>
      Registrar cliente
    </template>
    <div class="row pt-3">
      <div class="col">
        <div class="row">
          <div class="col text-center" v-if="esperandoRespuesta">
            <h4>Esperando a que el servicio de impuestos procese la factura</h4>
            <h5>Tiempo transcurrido
              <span
                  :class="'text-'+(tiempo<15?'success':(tiempo<30?'warning':'danger'))">
                                                        {{ tiempo }}</span> segundos.
              <button v-if="continuarEsperando" class="btn btn-sm btn-warning ms-2"
                      @click="tiempoAxios=0;continuarEsperando=false;temporizador();temporizadorAxios();">
                Seguir esperando la respuesta
              </button>
            </h5>
          </div>
          <div class="col text-center" v-if="!esperandoRespuesta">
            <h4>Factura Nro: <span>{{ factura.numeroFactura }}</span></h4>
            <h4>Estado: <span>{{ factura.estadoFactura }}</span></h4>
            <h4>Mensaje: <span>{{ factura.codigoError }} {{ factura.mensajeRespuesta }}</span></h4>
          </div>
        </div>
      </div>
    </div>
    <template #footer-wrapper>
      <h6 class="px-3"><em>En el listado de facturas tambien puede consultar el estado de la factura generada</em>
        <button class="btn btn-secondary" type="button" @click="close">Cerrar esta
          ventana
        </button>
      </h6>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";

var modalVerify;
export default {
  name: "Modal",
  data() {
    return {
      tiempo: 0,
      tiempoAxios: 0,
      factura: null,
      intervalo: null,
      intervaloAxios: null,
      sede_id: 0,
      factura_id: 0,
      continuarEsperando: false,
      esperandoRespuesta: true,
      modalVerify: false
    }
  },
  computed:{
    urlfactura() {
      let time = new Date().getTime()
      return this.$apiAdress + '/api/facturaenlinea/' + this.factura.id + '/facturapdf?token=' + localStorage.getItem('api_token')+'&timestamp='+time
    }
  },
  methods: {
    mostrar(sede_id, factura_id) {
      this.esperandoRespuesta = true
      this.tiempo = 0
      this.tiempoAxios = 0
      this.sede_id = sede_id
      this.factura_id = factura_id
      this.loadItems(sede_id, factura_id)
      modalVerify.temporizador()
      modalVerify.temporizadorAxios()
      modalVerify.modalVerify = true;
    },
    close() {
      clearInterval(modalVerify.intervaloAxios)
      clearInterval(modalVerify.intervalo)
      modalVerify.modalVerify = false;
    },
    temporizador() {
      modalVerify.intervalo = setInterval(() => {
        modalVerify.tiempo++;
      }, 1000)
    },
    temporizadorAxios() {
      modalVerify.intervaloAxios = setInterval(() => {
        if (modalVerify.tiempoAxios > 21) {
          clearInterval(modalVerify.intervaloAxios)
          clearInterval(modalVerify.intervalo)
          modalVerify.continuarEsperando = true
          return;
        }
        if (modalVerify.tiempoAxios === 7) {//11 = 65 seg, 7 = 31 seg,
          modalVerify.notificarCliente(this.sede_id, this.factura_id)
        }
        modalVerify.tiempoAxios++;
        modalVerify.loadItems(this.sede_id, this.factura_id)
      }, 5000)
    },
    async loadItems(sede_id, factura_id) {
      modalVerify.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + sede_id + '/verify?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: factura_id}
      )
          .then(function (response) {
            console.log(response.data)
            modalVerify.factura = response.data.factura;
            console.log(response.data.factura.estadoFactura);
            if (response.data.factura.estadoFactura == 'VALIDADA'
                || response.data.factura.estadoFactura == 'ANULADA'
                || response.data.impuestos_online == false) {
              modalVerify.$emit('actualizaGrilla')
              clearInterval(modalVerify.intervaloAxios)
              clearInterval(modalVerify.intervalo)
              modalVerify.esperandoRespuesta = false
              axios.get(
                  modalVerify.urlfactura,
                  {responseType: 'blob'}
              )
                  .then((response) => {
                    modalVerify.modalVerify = false;
                    modalVerify.$emit('mostrarDocumento', response.data)
                  })
                  .catch(function (error) {
                    modalVerify.$emit('showToast', 'Error', error.response.data.message, 'danger')
                  });
            } else if (response.data.factura.estadoFactura == 'RECHAZADA') {
              clearInterval(modalVerify.intervaloAxios)
              clearInterval(modalVerify.intervalo)
              modalVerify.esperandoRespuesta = false
            }
          })
          .catch(function (error) {
            console.log(error);
            modalVerify.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
        modalVerify.processing = false
      })
    },
    async notificarCliente(sede_id, factura_id) {
      modalVerify.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + sede_id + '/notify?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: factura_id}
      )
          .then(function (response) {
            clearInterval(modalVerify.intervaloAxios)
            clearInterval(modalVerify.intervalo)
            modalVerify.esperandoRespuesta = false
            axios.get(
                modalVerify.urlfactura+'&copia=true&notificar=true',
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalVerify.modalVerify = false;
                  modalVerify.$emit('mostrarDocumento', response.data)
                })
                .catch(function (error) {
                  modalVerify.$emit('showToast', 'Error', error.response.data.message, 'danger')
                });
          })
          .catch(function (error) {
            console.log(error);
            modalVerify.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
            modalVerify.processing = false
          })
    },
  },
  mounted() {
    modalVerify = this
  }
}
</script>
